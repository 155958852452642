export default {
    'Integral': '积分榜',
    'Schedule': '赛程',
    'Player': '球员榜',
    'All': '全部',
    'footballer': '球员',
    'team': '球队',
    'assist': '助攻',
    'goal': '进球',
    'TopCcorer': '射手榜',
    'assists': '助攻榜',
    'Sai': '赛',
    'Win/draw/lose': '胜/平/负',
    'Win': '胜',
    'Lose': '负',
    'in/out': '进/失',
    'integration': '积分',
    'Year': '年',
    'Month': '月',
    'Day1': '日',
    'Day': '天',
    'Hour': '时',
    'Minute': '分',
    'Second': '秒',
    'HT': '半场',
    'QT': '加时',
    'Corner': '角球',
    'tabM': '月',
    'tabD': '日',
    'Promotion zone': '晋级区',
    'undetermined': '待定区',
    'StartTime': '开赛时间:2023年8月25日-9月10日',

    'thirdGroup':'小组第三位球队排名',
    'firstGroup': '小组赛',
    'secondGroup': '二阶段 分组赛',
    'rankGroup': '17-32名 排位赛',
    'rankNum': '场比赛',
    'rank1': '冠军争夺',
    'rank2': '5-8排位赛',
    'rank3': '四分之一决赛',
    'rank4': '半决赛',
    'rank5': '决赛',
    'rank6': '季军赛',
    'rank7': '排位赛',
    'rank8': '第5名决赛',
    'rank9': '第7名决赛',
    'Final': '决赛',
    'conntdown': '倒计时',
    'nodata': '暂无数据',
    'Finish': '已完场'

}