// import {
//     clearAllCookie
//   } from '@/utils/fn'

  //import router from '@/router';
  import db from '@/utils/localStorage'
  import router from '@/router/index';
  import VueCookies from 'vue-cookies'
  
  export const state = {
    info: sessionStorage.getItem('info') ? JSON.parse(sessionStorage.getItem('info')) : {},
    memBindCardAccount: (sessionStorage.getItem('memBankAcc') != '') ? JSON.parse(sessionStorage.getItem('info')) : {},
    memberLoad: {},
    showMemberList:false,
    refreshInfo: {
      aid: "",
      nickname: "Guest",
      status: "",
      totalCredit: "0",
      unReadMsg: 0,
    },
    showInfo: false,
    token: '',
    login: db.get('LOGIN', {
      id: '',
      pass: '',
      remember: false,
      tel: '',
      telPass: ''
    }),
    securityInfo: {},
    isLogin: sessionStorage.getItem('isLogin') === 'true',
    memAccounts: [],
    memWallet: [],
    mainBal: 0,
    affiliateBal: 0,
    products: {},
    loadInfo: {},
    // messageContext:{},//站內信內容
    ticketCount: 0,
    currentPoints: 0,
  
    // active or inactive from CMS
    dailyTaskStatus: false,
    joinerStatus: false,
    referFriendStatus: false,
    ann2022Status: false,
  
    // special promotion data
    friendInvitationClaimCount: 0,
    taskProgressDaily: {},
    taskProgressJoiner: {},
  
    showLoginPopup: false,
    showRegisterPopup: false,
    showForgetPasswordPopup: false,
    showLoginPromoPopup: false,
    showTransactionDrawer: {
      show: false,
      type: 'deposit'
    },
    showLuckyPoints: true,
    showVerifiedPopup: false
  };
  
  export const mutations = {
    LOGOUT(state) {
      state.isLogin = false;
      state.info = {};
      state.memberLoad = {};
      state.refreshInfo = {
        aid: "",
        nickname: "Guest",
        status: "",
        totalCredit: "0",
        unReadMsg: 0,
      }
      state.showInfo = false;
      state.token = '';
      state.login = db.get('LOGIN', {
        id: '',
        pass: '',
        remember: false,
        tel: '',
        telPass: ''
      });
      state.securityInfo = {};
      state.mainBal = 0;
      state.memAccounts = [];
      state.memWallet = [];
      state.loadInfo = {};
      state.showMemberList = false;
      router.push('/')
      
    },
    SET_MEM_LIST_SHOW(state, payload) {
      state.showMemberList = payload;
    },
    SET_MEM_ACCOUNTS(state, payload) {
      state.memAccounts = payload;
    },
    SET_MEM_WALLET(state, payload) {
      state.memWallet = payload;
    },
    SET_MEM_BANK_ACCOUNTS(state, payload) {
      state.memBindCardAccount = payload;
    },
    CHANGE_SHOW_INFO(state, payload) {
      state.showInfo = payload
    },
    SET_DATA(state, payload) {
      state[payload.name] = payload.data
    },
    SET_MEMEBER_LOAD(state, payload) {
      state.memberLoad = Object.assign({}, payload);
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_REMEMBER(state, val) {
      db.save('LOGIN', val)
      state.login = val
    },
  
    setSecurityInfo(state, payload) {
      state.securityInfo = Object.assign({}, payload);
    },
    SET_LOGIN(state, payload) {
      // console.log('***login Payload***');
      // console.log(payload);
      db.save("AUTH", payload.jwt);
      state.isLogin = true;
      state.info = Object.assign({}, payload);
      try {
        sessionStorage.setItem('isLogin', 'true');
        sessionStorage.setItem('info', JSON.stringify(payload));
      } catch (e) {
        console.debug('Do not support sessionStorage');
      }
      var domain = window.location.hostname;
          if (domain !== 'localhost') {
              var domainArray = domain.split('.');
              domainArray[0] = '';
              domain = domainArray.join('.');
  
          }
  
      try {
        sessionStorage.setItem('isLogin', 'true');
        sessionStorage.setItem('info', JSON.stringify(payload));
        // set uuid
        VueCookies.set('uuid' , payload.uuid,null, '/',domain);
        VueCookies.set('sxt_uuid' , payload.uuid,null, '/',domain);
        VueCookies.set('sxp_uuid' , payload.uuid,null, '/',domain);
        VueCookies.set('sxc_uuid' , payload.uuid,null, '/',domain) ;
      } catch (e) {
        console.debug('Do not support sessionStorage');
      }
    },
    SET_PRODUCTS(state, payload) {
      state.products = Object.assign({}, payload);
    },
    // SET_MESCONTEXT(state, data){
    //   state.messageContext = data
    // }
  };
  
  export const actions = {
    // logout({
    //   commit
    // }) {
    //   // logout().then((res) => {
    //   //   const {
    //   //     code
    //   //   } = res;
    //   //   if (code > -1) {
    //   //     commit('LOGOUT')
    //   //     commit('favorite/GET_FAVORITES', [], { root: true })
    //   //     clearAllCookie();
    //   //     try {
    //   //       sessionStorage.setItem('isLogin', 'false');
    //   //       sessionStorage.setItem('info', '');
    //   //       sessionStorage.setItem('memBankAcc', '');
    //   //       sessionStorage.setItem('gamesAndProducts', '');
    //   //     } catch (e) {
    //   //       console.debug('Do not support sessionStorage');
    //   //     }
    //   //     // router.push({
    //   //     //   name: "login"
    //   //     // });
    //   //   }
    //   // });
    // },

    
  };
  
  export const namespaced = true;
  
  export const types = {};