export default {
    'Integral': 'Bảng xếp hạng',
    'Schedule': 'Lịch thi đấu',
    'Player': 'BXH cầu thủ',
    'All': 'Tất cả',
    'footballer': 'Cầu thủ',
    'team': 'Đội bóng',
    'assist': 'Kiến tạo',
    'goal': 'Ghi bàn',
    'TopCcorer': 'BXH Vua phá lưới',
    'assists': 'BXH Kiến tạo',
    'Sai': 'Giải đấu',
    'Win/draw/lose': 'Thắng/Hòa/Thua',
    'Win': 'Thắng',
    'Lose': 'Thua',
    'in/out': 'Vào/Trượt',
    'integration': 'Tích Điểm',
    'Year': 'Năm',
    'Month': 'Tháng',
    'Day1': 'Ngày',
    'Day': 'Ngày',
    'Hour': 'Giờ',
    'Minute': 'Phút',
    'Second': 'Giây',
    'HT': 'Nửa trận',
    'QT': 'Bù giờ',
    'Corner': 'Phạt góc',
    'tabM': 'Tháng',
    'tabD': 'Ngày',
    'Promotion zone': 'Giành quyền vào vòng trong',
    'undetermined': 'Chưa xác định',
    'thirdGroup':'BXH đứng thứ 3 trong bảng',
    'firstGroup': 'Vòng đấu bảng',
    'secondGroup': '二阶段 分组赛',
    'rankGroup': '17-32名 排位赛',
    'rankNum': 'Trận đấu',
    'rank1': '冠军争夺',
    'rank2': '5-8排位赛',
    'rank3': 'Tứ kết',
    'rank4': 'Bán kết',
    'rank5': 'Chung kết',
    'rank6': 'Tranh hạng ba',
    'rank7': '排位赛',
    'rank8': '第5名决赛',
    'rank9': '第7名决赛',
    'Final': 'CHUNG KẾT',
    'conntdown': 'Đếm ngược',
    'nodata': 'Không Có Đữ Liệu',
    'Finish': 'Kết Thúc'

}