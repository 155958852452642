const path = require('path')
const files = require.context('.', false, /\.js$/)
const modules = {}

files.keys().forEach((key) => {
  const name = path.basename(key,'.js')
  if (key ==='./index.js') return
  modules[name] = files(key).default || files(key);
});

export default {
  documentTitle: '2024欧洲杯',

  ...modules
}