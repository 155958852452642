<script src="main.js"></script>
<template>
  <div id="app" :class="[colorChange, 'hid-scrollbar', {'showHeadBg': showHeadBg}]" @scroll="scrollHandle">
    <!-- <keep-alive :include="($route.name == 'memberList' || $route.name == 'betList') ?'memberList':''"> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "App",
  data() {
    return {
      showHeadBg: false,
      scrollTime: null
    }
  },
  computed: {
    ...mapState("common", ["theme"]),
    colorChange() {
      return this.theme;
    },
  },
  methods: {
    scrollHandle(e) {
      if (this.scrollTime) return;
      this.scrollTime = setTimeout(() => {
        if (e.srcElement.scrollTop <= 0) {
          this.showHeadBg = false;
        } else {
          this.showHeadBg = true;
        }
        this.scrollTime = null;
      }, 50);
    }
  }
};
</script>
<style lang="scss">
.showHeadBg{
  .mainHeader .content{
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(50px);
  }
}
.dark{
  background: #000;
}
</style>