import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import i18n from './lang';

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable);

import '@/assets/styles/element-variables.scss'
import '@/assets/styles/index.scss'

Vue.prototype.getLocal = function(){
  return this.$i18n.locale.toLocaleUpperCase() || 'ZH'
} 

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')