import Vue from 'vue';
import Vuex from 'vuex';

import * as common from '@/store/common';
import * as user from '@/store/user';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    common,
    user,
  },
  getters: getters,
  plugins:[
    store => {
      console.log(store)
    }
  ]
});

export default store

